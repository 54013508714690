import React from "react"
import { useStaticQuery, graphql } from "gatsby"

import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Yebra from "../stylesheets/assets/yebra.jpg"
import BackgroundSlider from "gatsby-image-background-slider"
import Footer from "../components/footer"

const IndexPage = () => (
  <Layout>
    <section className="home  full">
      <SEO title="Home" />
      <BackgroundSlider
        query={useStaticQuery(graphql`
          query {
            backgrounds: allFile(
              filter: { sourceInstanceName: { eq: "backgrounds" } }
            ) {
              nodes {
                relativePath
                childImageSharp {
                  fluid(maxWidth: 3000, quality: 100) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }
          }
        `)}
      />
    </section>
    <Footer />
  </Layout>
)

export default IndexPage
