import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState, useEffect } from "react"
import Logo from "../stylesheets/assets/logo-negro.svg"

function Footeer({ siteTitle }) {

  return (
    <footer className="footer-opaque ofsInMTop ofsInMBottom footer">
        {/*Container*/}
        <div className="container">
          <div className="eight columns rights">
            <p>Copyrights © 2020 - {siteTitle}<br />
              <a href="mailto:ghribbenaissa@gmail.com">carlosyebra@hotmail.es</a></p>
          </div>
          <div className="eight columns tRight">
            <ul className="socials-footer socials">
              <li><a href="#" style={{opacity: 1}}>Facebook</a></li>
              <li><a href="#" style={{opacity: 1}}>Pinterest</a></li>
              <li><a href="#" style={{opacity: 1}}>Instagram</a></li>
              <li><a href="#" style={{opacity: 1}}>Twitter</a></li>
            </ul>
          </div>
        </div>
        {/*End container*/}
      </footer>
    
  )
}

Footeer.propTypes = {
  siteTitle: PropTypes.string,
}

Footeer.defaultProps = {
  siteTitle: ``,
}

export default Footeer

